import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import ClientTestimonials from "../components/client-testimonials";
import CtaBanner from "../components/cta-banner";
import HeroBannerSmall from "../components/hero-small";
import Layout from "../components/layout";
import OurServices from "../components/our-sevices";
import TrustPilotReviews from "../components/trust-pilot-reviews";
import TrustedBy from "../components/trusted-by";
import TwoColumnImageContent from "../components/two-column-image-content";
import WhyChoose from "../components/why-us";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "homepage" }) {
				homepage {
					bannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionCta2 {
							target
							title
							url
						}
						bannerSectionCta1 {
							target
							title
							url
						}
						bannerSectionBackground {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 50
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						bannerSectionLogos {
							link
							bannerLogo {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: FULL_WIDTH
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					trustedBy {
						trustedByHeading
						selectTrustedByLogos {
							nodes {
								... on WpTrustedByLogo {
									featureImage {
										featureImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					trustpilotReviews {
						trustpilotHeading
						trustpilotTagline
						trustpilotTitle
						trustpilotCta {
							target
							title
							url
						}
						trustpilotLogo {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
											width: 180
										)
									}
								}
							}
						}
						selectTrustpilotReview {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewPostFields {
										reviewAuthor
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewAuthorDesignation
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
					ourServices {
						ourServicesHeading
						ourServicesTagline
						ourServicesSubtitle
						ourServicesContent
						ourServicesCta {
							target
							title
							url
						}
						backgroundImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 50
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						selectOurServices {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceContent
										serviceCta {
											target
											title
											url
										}
										serviceImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					twoColumnImageContent {
						twoColumnHeading
						twoColumnContent
						twoColumnTagline
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnBackgroundColor
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					simpleCtaSection {
						ctaHeading
						ctaSubtitle
						ctaContent
						ctaButton1 {
							target
							title
							url
						}
						ctaButton2 {
							target
							title
							url
						}
						ctaBackground {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 50
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					twoColumnGoals {
						twoColumnHeading
						twoColumnTagline
						twoColumnSubtitle
						twoColumnContent
						twoColumnCta1 {
							target
							title
							url
						}
						twoColumnCta2 {
							target
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnImageBackgroundColor
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					whyUs {
						whyUsHeading
						whyUsTagline
						whyUsContent
						whyUsCta1 {
							target
							title
							url
						}
						whyUsCta2 {
							target
							title
							url
						}
						whyUsItems {
							whyUsItemHeading
							whyUsItemHeadingCopy
							whyUsItemColor
						}
					}
					getInTouch {
						getInTouchHeading
						getInTouchContent
						getInTouchCta {
							target
							title
							url
						}
						getInTouchBackground {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 50
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					clientTestimonials {
						selectTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, homepage },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(seoFields?.localBusinessSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node?.sourceUrl}`,
							width: `${seoFields?.image?.node?.mediaDetails?.width}`,
							height: `${seoFields?.image?.node?.mediaDetails?.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<Layout>
				<HeroBannerSmall
					heading={homepage?.bannerSection.bannerSectionHeading}
					content={homepage?.bannerSection.bannerSectionContent}
					btnCta1={homepage?.bannerSection.bannerSectionCta1}
					btnCta2={homepage?.bannerSection.bannerSectionCta2}
					bgImage={
						homepage?.bannerSection.bannerSectionBackground.node.localFile
							.childImageSharp.gatsbyImageData
					}
					bannerLogos={homepage?.bannerSection.bannerSectionLogos}
				/>
				<TrustedBy
					heading={homepage?.trustedBy?.trustedByHeading}
					logos={homepage?.trustedBy?.selectTrustedByLogos?.nodes}
				/>
				<TrustPilotReviews
					heading={homepage?.trustpilotReviews.trustpilotHeading}
					logo={homepage?.trustpilotReviews.trustpilotLogo}
					reviews={homepage?.trustpilotReviews.selectTrustpilotReview}
					title={homepage?.trustpilotReviews.trustpilotTitle}
					tagline={homepage?.trustpilotReviews.trustpilotTagline}
					btnCta1={homepage?.trustpilotReviews.trustpilotCta}
				/>
				<OurServices
					heading={homepage?.ourServices.ourServicesHeading}
					tagline={homepage?.ourServices.ourServicesTagline}
					subtitle={homepage?.ourServices.ourServicesSubtitle}
					content={homepage?.ourServices.ourServicesContent}
					ctaButton={homepage?.ourServices.ourServicesCta}
					services={homepage?.ourServices.selectOurServices?.nodes}
					bgImage={
						homepage?.ourServices.backgroundImage.node.localFile.childImageSharp
							.gatsbyImageData
					}
				/>
				<TwoColumnImageContent
					heading={homepage?.twoColumnImageContent.twoColumnHeading}
					content={homepage?.twoColumnImageContent.twoColumnContent}
					tagline={homepage?.twoColumnImageContent.twoColumnTagline}
					btnCta1={homepage?.twoColumnImageContent.twoColumnCta1}
					btnCta2={homepage?.twoColumnImageContent.twoColumnCta2}
					image={homepage?.twoColumnImageContent.twoColumnImage?.node}
					videoUrl={homepage?.twoColumnImageContent?.twoColumnVideoYoutube}
					video={homepage?.twoColumnImageContent.twoColumnVideo?.node}
					bgColor={homepage?.twoColumnImageContent.twoColumnBackgroundColor}
					textColor={"#E8BC38"}
				/>
				<CtaBanner
					useBgImage
					heading={homepage?.simpleCtaSection.ctaHeading}
					content={homepage?.simpleCtaSection.ctaContent}
					subtitle={homepage?.simpleCtaSection.ctaSubtitle}
					btnCta1={homepage?.simpleCtaSection.ctaButton1}
					btnCta2={homepage?.simpleCtaSection.ctaButton2}
					bgImage={
						homepage?.simpleCtaSection.ctaBackground?.node.localFile
							.childImageSharp.gatsbyImageData
					}
				/>
				<TwoColumnImageContent
					heading={homepage?.twoColumnGoals.twoColumnHeading}
					subTitle={homepage?.twoColumnGoals.twoColumnSubtitle}
					content={homepage?.twoColumnGoals.twoColumnContent}
					tagline={homepage?.twoColumnGoals.twoColumnTagline}
					btnCta1={homepage?.twoColumnGoals.twoColumnCta1}
					image={homepage?.twoColumnGoals.twoColumnImage?.node}
					video={homepage?.twoColumnGoals.twoColumnVideo?.node}
					videoUrl={homepage?.twoColumnGoals?.twoColumnVideoYoutube}
					bgColor={homepage?.twoColumnGoals.twoColumnImageBackgroundColor}
					flip={true}
					textColor={"#36B49F"}
				/>
				<WhyChoose
					heading={homepage?.whyUs.whyUsHeading}
					content={homepage?.whyUs.whyUsContent}
					tagline={homepage?.whyUs.whyUsTagline}
					btnCta1={homepage?.whyUs.whyUsCta1}
					btnCta2={homepage?.whyUs.whyUsCta2}
					whyChooseItems={homepage?.whyUs.whyUsItems}
				/>
				<CtaBanner
					useBgImage
					heading={homepage?.getInTouch.getInTouchHeading}
					content={homepage?.getInTouch.getInTouchContent}
					btnCta1={homepage?.getInTouch.getInTouchCta}
					bgImage={
						homepage?.getInTouch.getInTouchBackground?.node.localFile
							.childImageSharp.gatsbyImageData
					}
				/>
				<ClientTestimonials
					testimonials={homepage?.clientTestimonials.selectTestimonials?.nodes}
				/>
				<TrustedBy
					heading={homepage?.trustedBy?.trustedByHeading}
					logos={homepage?.trustedBy?.selectTrustedByLogos?.nodes}
				/>
			</Layout>
		</>
	);
};

export default IndexPage;
