import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";
import bgImage from "../images/why-choose-bg.webp";
import WhyChooseIcon from "../images/whychoose.svg";

const WhyUs = ({
	heading,
	tagline,
	content,
	btnCta1,
	btnCta2,
	whyChooseItems,
}) => {
	return (
		<section
			className="py-5 py-md-8 bg-blue-bg whyus-wrapper"
			style={sectionWrapper(bgImage)}
		>
			<Container>
				<Row className="align-items-lg-center">
					<Col lg={7} className="mb-4 mb-lg-0 text-start">
						<span className="text-secondary">{tagline}</span>
						<h2 className="my-auto text-white pb-2">{heading}</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: content,
							}}
							className="fw-light text-white text-start mt-3"
						/>
						<div className="d-flex align-items-lg-center flex-column flex-md-row mt-4">
							<Button
								as={Link}
								href={btnCta1.url}
								size="lg"
								className="px-4 py-3 me-3 montserrat-bold fs-6 mb-3 mb-lg-0"
								target={btnCta1.target}
								variant="secondary"
							>
								{btnCta1.title}
							</Button>
							<Button
								as={Link}
								href={btnCta2.url}
								size="lg"
								className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-white border-0"
								target={btnCta2.target}
							>
								{btnCta2.title}
								<IoIosArrowForward className="fs-4 ms-2" />
							</Button>
						</div>
					</Col>
				</Row>
				{whyChooseItems.length > 0 && (
					<Row className="py-5">
						{whyChooseItems.map((item, i) => (
							<Col lg={6} xxl={3} key={i} className="mb-4 mb-xxl-0">
								<div
									className="py-3 px-4 rounded-5 h-100"
									style={{ border: `4px solid ${item.whyUsItemColor}` }}
								>
									<WhyChooseIcon style={{ fill: item.whyUsItemColor }} />
									<StaticImage
										src={"./images/whychoose.svg"}
										alt={item.whyUsItemHeading}
										style={{ fill: item.whyUsItemColor }}
									/>
									<h4 className="text-white fs-5 py-3 mb-0">
										{item.whyUsItemHeading}
									</h4>
									<div
										className="text-white content-wrapper"
										dangerouslySetInnerHTML={{
											__html: item.whyUsItemHeadingCopy,
										}}
									></div>
								</div>
							</Col>
						))}
					</Row>
				)}
			</Container>
		</section>
	);
};

export const sectionWrapper = (imgUrl) => ({
	background: `url(${imgUrl}) no-repeat`,
	backgroundPosition: "top right",
	backgroundSize: "40%",
});

export default WhyUs;
